import React from 'react';
import { Typography } from 'components/atoms/typography';
enum ProductCardVariant {
  brand = 'brand',
  name = 'name',
  price = 'price',
  discountPrice = 'discount-price',
  gram = 'gram',
  thc = 'thc',
  type = 'type',
}
type TProductCardTypographyProps = {
  variant: 'brand' | 'name' | 'price' | 'discount-price' | 'gram' | 'thc' | 'type';
  text: string;
  className?: string;
};
const ProductCardTypography = ({ variant, text, className }: TProductCardTypographyProps) => {
  switch (variant) {
    case ProductCardVariant.brand:
      return (
        text && (
          <Typography variant="body-small" className={`truncate !text-grey-400 ${className}`}>
            {text}
          </Typography>
        )
      );
    case ProductCardVariant.name:
      return (
        text && (
          <Typography variant="body-large-bold" className={`truncate py-1 lg:py-2 ${className}`}>
            {text}
          </Typography>
        )
      );
    case ProductCardVariant.price:
      return (
        text && (
          <Typography variant="body-large-bold" className={`${className}`}>
            ${text}
          </Typography>
        )
      );
    case ProductCardVariant.discountPrice:
      return (
        text && (
          <Typography variant="body-small" className={`!text-grey-400 line-through ${className}`}>
            ${text}
          </Typography>
        )
      );
    case ProductCardVariant.gram:
      return (
        text && (
          <Typography variant="body-small" className={`!text-grey-400 ${className}`}>
            {text}
          </Typography>
        )
      );
    case ProductCardVariant.thc:
      return (
        text && (
          <Typography variant="body-small" className={`truncate !text-grey-400 ${className}`}>
            {text}
          </Typography>
        )
      );
    case ProductCardVariant.type:
      return (
        text && (
          <Typography variant="body-small" className={`truncate !text-grey-400 ${className}`}>
            {text}
          </Typography>
        )
      );
  }
};

export default ProductCardTypography;
