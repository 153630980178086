import tw from 'tailwind-styled-components';
import { CommonButton, IButtonProps } from 'components/atoms/buttons/common-buttons/button';
import { TProps, Typography } from 'components/atoms/typography';
import styles from './styles.module.css';

export const BannerRewardButton = tw(CommonButton)<IButtonProps>`
    w-fit
`;

export const BannerRewardTitleText = tw(Typography)<TProps>`
    text-white
`;

export const BannerRewardDescriptionText = tw(BannerRewardTitleText)`
    md:pb-5
    xl:pb-6
`;

export const BannerRewardsContainer = tw.div`
    flex
    flex-col
    md:flex-row
    max-w-[1920px]
    mx-auto
`;

export const BIRewardsImage = tw.div`
    flex
    w-full
    md:max-w-[50%]
    overflow-hidden
`;

export const BannerImgContainer = tw.div`
    w-full
    relative
`;

export const BannerImgHeighAspectRatio = tw.img`
    invisible
`;

export const BIRewardsDetailContainer = tw.div`
    ${() => styles['b-i-rewards-detail-container']}
`;

export const BIRewardsOverlayImg = tw.div`
    ${() => styles['b-i-rewards-overlay-img']}
`;

export const BIRewardsBody = tw.div`
    ${() => styles['b-i-rewards-body']}
`;
