import tw from 'tailwind-styled-components';
import { CommonButton, IButtonProps } from 'components/atoms/buttons/common-buttons/button';
import { TProps, Typography } from 'components/atoms/typography';
import styles from './styles.module.css';

interface SpecialOfferBannerProps {
  $richTextCustom?: boolean;
}

export const BannerSpecialOfferContainer = tw.div<SpecialOfferBannerProps>`
    bg-white
    ${(props) => (props.$richTextCustom ? '' : 'p-5 md:p-10 xl:px-[60px] xl:py-16')}
`;

export const BannerSpecialButton = tw(CommonButton)<IButtonProps>`
    !text-light
`;

export const BannerSpecialText = tw(Typography)<TProps>``;

export const BannerSpecialTitleText = tw(BannerSpecialText)`
    text-center
    md:text-left
`;

export const BannerSpecialOfferBody = tw.div`
    flex
    flex-col
    md:flex-row
    bg-grey-100
    justify-between
`;

export const BannerSpecialOfferContentWrapper = tw.div`
    ${() => styles['banner-special-offer-content-wrapper']}
`;

export const BannerSpecialOfferContent = tw.div`
    ${() => styles['banner-special-offer-content']}
`;

export const BannerSpecialOfferImageWrapper = tw.div`
    flex
    w-full
    max-w-[727px]
    md:max-w-[725px]
    xl:max-w-[1013px]
`;
