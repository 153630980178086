import React from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionProps,
} from '@material-tailwind/react/components/Accordion';
import tw from 'tailwind-styled-components';

export const AccordionIcons = tw.div`
    h-5 
    w-5
    transition-transform
`;

interface IAccordionProps extends AccordionProps {
  $faqWidth: boolean;
  $style?: string;
}

export const AccordionWrapper: React.FC<IAccordionProps> = tw(Accordion)`
    mb-3 
    bg-grey-100 
    lg:mb-4
    ${(p: IAccordionProps) => p.$faqWidth && 'lg:max-w-[736px]'}
    ${(p: IAccordionProps) => p.$style}
`;

interface IAccordianHeaderProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const AccordianHeader: React.FC<IAccordianHeaderProps> = tw(AccordionHeader)`
    border-none 
    px-5 
    py-6 
    lg:px-6 
    lg:py-7
    text-grey-500
`;

export const AccordianBody = tw(AccordionBody)`
    px-5
    text-sm
    lg:text-base
    lg:leading-5
    leading-4
    font-normal
    font-arial
    lg:tracking-[0.16px]
    tracking-[0.14px]
    lg:px-6 
    pb-8 
    text-grey-400
    pt-0
    [&_a]:font-bold
    [&_a]:underline
    [&_a]:text-leaf
    [&_ul]:list-disc 
    [&_ol]:list-decimal
    marker:[&_ul]:text-resin 
    marker:[&_ol]:text-leaf
    [&_ol]:pl-6
    [&_ul]:pl-6
    [&_li]:pt-[10px]
`;
