import { useState } from 'react';
import { IPageChange } from 'components/atoms/pagination/pagination.types';

interface UsePaginationReturn {
  currentPage: number;
  totalPage: number;
  next: () => void;
  prev: () => void;
  setCurrentPageTo: (x: number) => void;
  startIndex: number;
  endIndex: number;
  onPageChange?: (x: IPageChange) => void;
}

const usePagination = (count: number, itemsPerPage = 1, onPageChange): UsePaginationReturn => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPage = Math.ceil(count / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage > count ? count : startIndex + itemsPerPage;

  const next = () => {
    const newIndex = Math.min(currentPage + 1, totalPage);
    setCurrentPage(newIndex);
    onPageChange({
      page: newIndex,
      startIndex: currentPage * itemsPerPage,
      endIndex: currentPage * itemsPerPage + itemsPerPage > count ? count : currentPage * itemsPerPage + itemsPerPage,
    });
  };

  const prev = () => {
    const newIndex = Math.max(currentPage - 1, 1);
    setCurrentPage(newIndex);
    onPageChange({
      page: newIndex,
      startIndex: (newIndex - 1) * itemsPerPage,
      endIndex:
        (newIndex - 1) * itemsPerPage + itemsPerPage > count ? count : (newIndex - 1) * itemsPerPage + itemsPerPage,
    });
  };

  const setCurrentPageTo = (x: number) => {
    setCurrentPage(x);
  };

  return {
    next,
    prev,
    setCurrentPageTo,
    currentPage,
    totalPage,
    startIndex,
    endIndex,
  };
};

export default usePagination;
