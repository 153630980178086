import Image, { ImageProps } from 'next/image';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage } from 'helpers/types';
import { isVideoUrl } from 'helpers/utils/is-video-file';
import { tablet } from 'helpers/utils/screensizes';

type ImagePropsWithoutSrc = Omit<ImageProps, 'src'>;

interface ImageProp extends ImagePropsWithoutSrc {
  'data-testid'?: string;
  imageHeight?: number;
  imageWidth?: number;
}
interface IProps {
  desktop: IContentfulImage;
  mobile: IContentfulImage;
  imageProps?: ImageProp;
}

const ResponsiveImageOrVideo = ({ desktop, mobile, imageProps }: IProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const bannerImageUrl = isTablet ? desktop?.url : mobile?.url || desktop?.url;
  const bannerImageAlt = isTablet ? desktop?.title : mobile?.title || desktop?.title;
  const bannerImageTitle = isTablet ? desktop?.title : mobile?.title || desktop?.title;
  const bannerImageWidth = isTablet ? desktop?.width : mobile?.width || desktop?.width;
  const bannerImageHeight = isTablet ? desktop?.height : mobile?.height || desktop?.height;
  const bannerImageAriaLabel = isTablet ? desktop?.title : mobile?.title;

  return (
    <>
      {(desktop || mobile) && isVideoUrl(bannerImageUrl || '') ? (
        <video className="h-full w-full" controls autoPlay loop muted>
          <source src={bannerImageUrl} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <Image
          src={bannerImageUrl}
          width={imageProps?.imageWidth || bannerImageWidth}
          height={imageProps?.imageHeight || bannerImageHeight}
          title={bannerImageTitle}
          alt={bannerImageAlt}
          loader={({ src }) => src}
          aria-label={bannerImageAriaLabel}
          {...imageProps}
          priority
        />
      )}
    </>
  );
};

export default ResponsiveImageOrVideo;
