import React from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import SimpleImageOrVideo from 'components/molecules/image-or-video/simple-image-or-video';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TBannerStatementProps } from 'helpers/types/organisms/banners/banner-statement';
import { ReplaceUrlWithHost, getLinkTarget, getPathMenuFiltersLink } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import {
  BSDesktopBody,
  BSDesktopButton,
  BSDesktopContainer,
  BSDesktopContent,
  BSDesktopIconImg,
  BSDesktopLeftImg,
  BSDesktopRightImg,
  BSDesktopRightImgBox,
  BSDestopTitle,
  BSMobileButton,
  BSMobileContainer,
  BSMobileContent,
  BSMobileIconImg,
  BSMobileLeftImg,
  BSMobileRightImg,
  BSMobileTitle,
} from './styles';

const BannerContentDefault = ({
  title,
  overlayImage,
  image,
  additionalImage,
  ctaCollection,
  host,
  handleSegmentEvent,
}: TBannerStatementProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const paersedCtaUrl = ctaCollection?.longUrl ? ctaCollection?.longUrl : ctaCollection?.url;
  const ctaUrl = ReplaceUrlWithHost(paersedCtaUrl || '{{url}}/', host);
  const newUrl = getPathMenuFiltersLink(ctaUrl);
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  return (
    <>
      {!isTablet ? (
        <BSMobileContainer ref={targetRef} data-automationid="c10_banner_00">
          <BSMobileLeftImg>
            {additionalImage?.mobileImage?.url && <SimpleImageOrVideo imageOrVideo={additionalImage?.mobileImage} />}
          </BSMobileLeftImg>
          <BSMobileContent>
            <BSMobileIconImg>
              {overlayImage?.mobileImage?.url && (
                <Image
                  height={overlayImage?.mobileImage?.height}
                  width={overlayImage?.mobileImage?.width}
                  src={overlayImage?.mobileImage?.url}
                  loader={(options) => options.src}
                  alt={overlayImage?.mobileImage?.title}
                />
              )}
            </BSMobileIconImg>
            {title && (
              <BSMobileTitle>
                <Typography variant="h2">{title}</Typography>
              </BSMobileTitle>
            )}
            {newUrl && ctaCollection?.title && (
              <BSMobileButton>
                <CommonButton
                  as="a"
                  target={getLinkTarget(newUrl)}
                  href={newUrl}
                  color="green"
                  button-type="secondary"
                  onClick={() => handleSegmentEvent(SegmentEvents.BANNER_CLICKED)}
                >
                  {ctaCollection?.title}
                </CommonButton>
              </BSMobileButton>
            )}
          </BSMobileContent>
          <BSMobileRightImg>
            {image?.mobileImage?.url && (
              <Image
                height={image?.mobileImage?.height}
                width={image?.mobileImage?.width}
                src={image?.mobileImage.url}
                loader={(options) => options.src}
                alt={image?.mobileImage?.title}
              />
            )}
          </BSMobileRightImg>
        </BSMobileContainer>
      ) : (
        <BSDesktopContainer ref={targetRef} data-automationid="c10_banner_00">
          <BSDesktopContent>
            <BSDesktopLeftImg>
              {image?.desktopImage?.url && (
                <Image
                  height={image?.desktopImage?.height}
                  width={image?.desktopImage?.width}
                  src={image?.desktopImage?.url}
                  loader={(options) => options.src}
                  alt={image?.desktopImage?.title}
                />
              )}
            </BSDesktopLeftImg>
            <BSDesktopBody data-testid="desktop-body">
              <BSDesktopIconImg>
                {overlayImage?.desktopImage?.url && (
                  <Image
                    height={overlayImage?.desktopImage?.height}
                    width={overlayImage?.desktopImage?.width}
                    src={overlayImage?.desktopImage?.url}
                    loader={(options) => options.src}
                    alt={overlayImage?.desktopImage?.title}
                  />
                )}
              </BSDesktopIconImg>
              {title && (
                <BSDestopTitle>
                  <Typography variant="h2">{title}</Typography>
                </BSDestopTitle>
              )}
            </BSDesktopBody>
            {newUrl && ctaCollection?.title && (
              <BSDesktopButton>
                <CommonButton
                  as="a"
                  href={newUrl}
                  target={getLinkTarget(newUrl)}
                  color="green"
                  button-type="secondary"
                  onClick={() => handleSegmentEvent(SegmentEvents.BANNER_CLICKED)}
                >
                  {ctaCollection?.title}
                </CommonButton>
              </BSDesktopButton>
            )}
          </BSDesktopContent>
          <BSDesktopRightImgBox>
            <BSDesktopRightImg>
              {additionalImage?.desktopImage?.url && (
                <Image
                  height={additionalImage?.desktopImage?.height}
                  width={additionalImage?.desktopImage?.width}
                  src={additionalImage?.desktopImage?.url}
                  loader={(options) => options.src}
                  alt={additionalImage?.desktopImage?.title}
                />
              )}
            </BSDesktopRightImg>
          </BSDesktopRightImgBox>
        </BSDesktopContainer>
      )}
    </>
  );
};

export default BannerContentDefault;
