import React from 'react';
import { useRouter } from 'next/router';
import { Typography } from 'components/atoms/typography';
import ExternalLinkIcon from 'public/icons/figma/external-link.svg';
import { useAppSelector } from 'redux/hooks';
import { SourcesCardBox, SourcesCardIcon, SourcesCardTitle } from './styles';

type TProps = {
  title: string;
  description: string;
  url: string;
};

const SourcesCard = ({ title, description, url }: TProps) => {
  const host = useAppSelector((store) => store?.hostReducer?.host);
  const router = useRouter();

  const handleClick = () => {
    if (url.includes(host)) {
      router.push(url);
    } else {
      window.open(url, '_blank');
    }
  };
  return (
    <SourcesCardBox data-testid="sources-card">
      <SourcesCardTitle>
        <Typography
          className="truncate"
          as={'a'}
          variant="h5"
          href={url}
          target={url.includes(host) ? '_self' : '_blank'}
        >
          {title}
        </Typography>
        <SourcesCardIcon onClick={handleClick}>
          <ExternalLinkIcon />
        </SourcesCardIcon>
      </SourcesCardTitle>
      <Typography variant="body-small" className="self-stretch overflow-hidden truncate text-grey-400">
        {description}
      </Typography>
    </SourcesCardBox>
  );
};

export default SourcesCard;
