import tw from 'tailwind-styled-components';

export const BSDesktopContainer = tw.div`
    hidden 
    lg:grid 
    bg-grey-100 
    grid-cols-1 
    lg:grid-cols-6
`;

export const BSDesktopContent = tw.div`
    grid 
    lg:grid-cols-5 
    lg:col-span-5
`;

export const BSDesktopLeftImg = tw.div`
    flex 
    items-end 
    float-left
`;

export const BSDesktopBody = tw.div`
    col-span-4 
    pt-[120px]
`;

export const BSDesktopIconImg = tw.div`
    pb-[64px] 
    flex 
    items-center 
    justify-center
`;

export const BSDestopTitle = tw.div`
    flex 
    items-center 
    justify-center 
    text-center 
    w-[88%]
`;

export const BSDesktopButton = tw.div`
    flex 
    items-center 
    justify-center
    pt-12
    pb-[120px] 
    col-start-2 
    col-end-6
`;

export const BSDesktopRightImgBox = tw.div`
    hidden 
    lg:block
`;

export const BSDesktopRightImg = tw.div`
    float-right
`;

export const BSMobileContainer = tw.div`
    flex 
    lg:hidden 
    flex-wrap 
    w-full 
    relative 
    bg-grey-100 
`;

export const BSMobileLeftImg = tw.div`
    absolute 
    right-0
`;

export const BSMobileContent = tw.div`
    relative 
    py-[60px] 
    px-10
    z-10 
    w-full
`;

export const BSMobileIconImg = tw.div`
    flex 
    items-center 
    justify-center
`;

export const BSMobileTitle = tw.div`
    py-8 
    text-center 
    flex 
    items-center 
    justify-center
`;

export const BSMobileButton = tw.div`
    flex 
    items-center 
    justify-center
`;

export const BSMobileRightImg = tw.div`
    absolute 
    bottom-0  
    left-0
`;
