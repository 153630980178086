import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import MinusIcon from 'public/icons/figma/minus.svg';
import PlusIcon from 'public/icons/figma/plus.svg';
import { AccordianBody, AccordianHeader, AccordionIcons, AccordionWrapper } from './styles';

type TAccordionProps = {
  data: { question: string; answer: { json: any } };
  index: number;
  shouldOpen: boolean;
  handleOpen: (index: number) => void;
  faqWidth?: boolean;
  wrapperStyle?: string;
};

const AccordionIcon = ({ open }: { open: boolean }) => {
  return (
    <AccordionIcons>
      {open ? <MinusIcon className="text-leaf lg:h-6 lg:w-6" /> : <PlusIcon className="text-leaf lg:h-6 lg:w-6" />}
    </AccordionIcons>
  );
};
const FaqAccordion = ({ data, index, shouldOpen, handleOpen, faqWidth, wrapperStyle }: TAccordionProps) => {
  return (
    <AccordionWrapper
      open={shouldOpen}
      $faqWidth={!faqWidth}
      $style={wrapperStyle}
      data-testid="sectionfaq"
      icon={<AccordionIcon open={shouldOpen} />}
    >
      <AccordianHeader onClick={() => handleOpen(index)}>
        {data?.question && (
          <Typography variant="body-large" className="!text-grey-500">
            {data?.question}
          </Typography>
        )}
      </AccordianHeader>
      <AccordianBody>
        {data?.answer && (
          <div className="[&_div_p]:pb-0">
            <ContentfulRichText data={data.answer} />
          </div>
        )}
      </AccordianBody>
    </AccordionWrapper>
  );
};

export default FaqAccordion;
