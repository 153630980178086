import { Card } from '@material-tailwind/react/components/Card';
import { Progress, ProgressProps } from '@material-tailwind/react/components/Progress';
import tw from 'tailwind-styled-components';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { IconColor, Variant } from './pagination.types';

export const Container = tw.div`
  flex
  items-center
`;

interface IProgressBarStatus extends React.HTMLAttributes<HTMLElement> {
  $white?: boolean;
  $variant?: Variant;
  $widthAuto?: boolean;
}

export const ProgressBarStatus: React.FC<IProgressBarStatus> = tw.div`
  ${(props: IProgressBarStatus) => (props.$white ? '[&>p]:!text-white' : '[&>p]:!text-grey-400')}
  ${(props) => (props.$variant === 'small' ? 'w-[54px] lg:w-auto' : '')}
  ${(props) => (props.$variant === 'large' ? 'w-20' : '')}
  ${(props) =>
    props.$variant === 'pill-mobile' ? `${props.$widthAuto ? 'w-auto' : 'w-12'} [&>p]:!text-sm [&>p]:!leading-4` : ''}
`;

interface IProgressBarIndicator extends ProgressProps {
  $white?: boolean;
  $variant: Variant;
}

export const ProgressBarIndicator: React.FC<IProgressBarIndicator> = tw(Progress)`
  ${(props: IProgressBarIndicator) => (props.$white ? `[&>div]:bg-white` : '[&>div]:bg-resin')}
  ${(props: IProgressBarIndicator) => (props.$variant === 'small' ? 'w-12 mx-5 lg:mx-3' : '')}
  ${(props: IProgressBarIndicator) => (props.$variant === 'large' ? 'w-[120px] mx-9 lg:mx-8' : '')}
  lg:w-24
  h-1
  rounded-sm
  ${(props: IProgressBarIndicator) => (props.$variant === 'pill-desktop' ? '!w-16 ml-8' : '')}
  ${(props: IProgressBarIndicator) => (props.$variant === 'center-carousel' ? '!w-14 lg:!w-16' : '')}
`;

interface IPrevIcon extends React.HTMLAttributes<HTMLElement> {
  $disabled?: boolean;
  $color: IconColor;
  $variant?: Variant;
}

export const PrevIcon: React.FC<IPrevIcon> = tw(ChevronLeftIcon)`
  w-6
  h-6
  ${(props: IPrevIcon) => (props.$variant === 'small' ? 'mr-5 lg:mr-3' : '')}
  ${(props: IPrevIcon) => (props.$variant === 'large' ? 'mr-6 mr-6' : '')}
  ${(props: IPrevIcon) =>
    props.$disabled
      ? 'text-grey-300'
      : `${
          props.$color === 'white'
            ? 'text-white'
            : props.$color === 'resin'
            ? 'text-grey-500'
            : props.$color === 'leaf'
            ? 'text-leaf'
            : ''
        } cursor-pointer`}
`;

interface INextIcon extends React.HTMLAttributes<HTMLElement> {
  $disabled?: boolean;
  $color: IconColor;
}
export const NextIcon: React.FC<INextIcon> = tw(ChevronRightIcon)`
  w-6
  h-6
  ${(props: INextIcon) =>
    props.$disabled
      ? 'text-grey-300'
      : `${
          props.$color === 'white'
            ? 'text-white'
            : props.$color === 'resin'
            ? 'text-grey-500'
            : props.$color === 'leaf'
            ? 'text-leaf'
            : ''
        } cursor-pointer`}
`;

export const IconContainer = tw.div`
        flex
        flex-row
`;
interface PDPCarouselPaginationCardMobileProps extends React.HTMLAttributes<HTMLElement> {
  $className?: string;
  $backgroundTransparent?: boolean;
}
export const PDPCarouselPaginationCardMobile: React.FC<PDPCarouselPaginationCardMobileProps> = tw(Card)`
  flex
  flex-row
  w-fit
  py-2
  px-3
  items-center
  rounded-3xl
  ${(p: PDPCarouselPaginationCardMobileProps) => (p.$backgroundTransparent ? 'bg-transparent shadow-none' : '')}

`;

export const PDPCarouselPaginationCardDesktop = tw(Card)`
  flex
  flex-row
  py-[22px]
  px-10
  rounded-[32px]
  items-center
  justify-between
`;

export const PDPCarouselPaginationDesktopContainer = tw.div`
  flex
  justify-between
  items-cetner
  w-full
`;

interface TIconCard extends React.HTMLAttributes<HTMLElement> {
  $mr?: number;
}
export const IconCard: React.FC<TIconCard> = tw(Card)`
  w-10
  lg:w-16
  h-10
  lg:h-16
  rounded-[32px]
  items-center
  justify-center
  ${(props: TIconCard) => (props.$mr ? `mr-${props.$mr}` : '')}
`;
