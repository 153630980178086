import React from 'react';
import { Dialog, DialogHeader } from '@material-tailwind/react/components/Dialog';
import { VideoCloseIcon, VideoContainer, VideoDiv } from './styles';

type TVideo = {
  isOpen: boolean;
  onClick: () => void;
  videoData: any;
};

const RewardVideo = ({ isOpen, onClick, videoData }: TVideo) => {
  return (
    <>
      <Dialog open={isOpen} handler={onClick} size="xxl" className="overflow-y-auto bg-overlay">
        <DialogHeader className="flex !justify-end">
          <VideoCloseIcon onClick={onClick} />
        </DialogHeader>
        <VideoContainer>
          <VideoDiv
            dangerouslySetInnerHTML={{
              __html: videoData?.json?.content[0]?.content[0]?.value,
            }}
          />
        </VideoContainer>
      </Dialog>
    </>
  );
};

export default RewardVideo;
