import tw from 'tailwind-styled-components';

export const ButtonContainer = tw.div`
    [&>*:nth-child(1)]:absolute
    [&>*:nth-child(1)]:md:top-[53%]
    [&>*:nth-child(1)]:sm:top-[59%]
    [&>*:nth-child(1)]:md:left-[13%]
    [&>*:nth-child(1)]:sm:left-[20%]
    [&>*:nth-child(1)]:z-[10]

    [&>*:nth-child(2)]:absolute
    [&>*:nth-child(2)]:md:top-[53%]
    [&>*:nth-child(2)]:sm:top-[59%]
    [&>*:nth-child(2)]:md:left-[40%]
    [&>*:nth-child(2)]:sm:left-[75%]

    [&>*:nth-child(2)]:z-[10]

`;

export const buttonClass = `
    absolute
    top-[50%]
    z-[10]
`;

export const PaginationContainer = tw.div`
    mt-6
    flex
    justify-center
`;

export const PaginationContainerInner = tw.div`
    h-full
    w-full
    relative
`;
