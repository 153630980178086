import { ReactNode } from 'react';
import { LabeLContainer } from './styles';

export interface ILabelProps {
  variant: 'resin' | 'kief' | 'grey-200' | 'white-border' | 'white' | 'grey-100';
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
}

const Label = ({ variant, children, className, icon, ...rest }: ILabelProps) => {
  return (
    <LabeLContainer $variant={variant} className={`${className ?? ''}`} {...rest}>
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </LabeLContainer>
  );
};

export default Label;
