import tw from 'tailwind-styled-components';

export const SourcesCardBox = tw.div`
    flex
    flex-col
    w-[350px]
    2xl:w-[432px]
    md:w-[293px]
    items-start
    xl:gap-4
    md:gap-[10px]
    gap-4
    bg-white
    rounded-md
    xl:px-8
    xl:pt-8
    xl:pb-7
    md:p-5
    py-6
    px-5
`;

export const SourcesCardTitle = tw.div`
    flex
    flex-row
    xl:gap-3
    gap-2
    items-center
    text-leaf
    w-full
`;

export const SourcesCardIcon = tw.div`
    [&_svg]:md:h-6
    [&_svg]:md:w-6
    [&_svg]:h-5
    [&_svg]:w-5
`;
