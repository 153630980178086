import tw from 'tailwind-styled-components';
import CloseIcon from 'public/icons/figma/close-small.svg';

export const SnackContainer = tw.div<{ $backgroundColor: string; $zindexPlacement: string }>`
    fixed
    right-0
    flex
    w-full
    h-12
    md:h-11 
    xl:h-14
    top-0
    ${(p) => (p.$backgroundColor === 'leafGreen' ? 'bg-leaf text-white [&_a]:text-kief' : null)}
    ${(p) => (p.$backgroundColor === 'kiefGreen' ? 'bg-kief text-leaf ' : null)}
    ${(p) => (p.$backgroundColor === 'kiefGreen' ? 'lg:gap-[280px] ' : null)}
    ${(p) => (p.$zindexPlacement ? p.$zindexPlacement : 'z-[20]')}
    my-auto
    items-center
    justify-center
`;

export const SnackContent = tw.p<{ $backgroundColor: string }>`
    text-sm
    xl:text-base
    md:text-[15px]
    leading-4
    tracking-[0.14px]
    py-[12px]
    !px-[0]
    md:text-base
    md:leading-[18px]
    xl:leading-5
    md:py-[16px]
    md:tracking-[0.15px]
    xl:tracking-[0.16px]
    font-arial
    font-bold
`;

export const SnackBarWrapper = tw.div`
    flex
    h-auto
    text-center
    lg:whitespace-nowrap
`;

export const StyledExit = tw(CloseIcon)<{ $backgroundColor: string }>`
    absolute
    right-[50%]
    top-[50%]
    translate-y-[-50%]
    translate-x-[-50%]
    text-right
    inline-block
    text-md
    md:text-lg
    h-auto
    w-auto
    ${(p) => (p.$backgroundColor === 'leafGreen' ? 'text-white' : null)}
    ${(p) => (p.$backgroundColor === 'kiefGreen' ? 'text-leaf ' : null)}

`;

export const ButtonContainer = tw.div<{ $backgroundColor: string }>`
    fixed
    right-0
    lg:pr-[60px]
    text-leaf
    sm:ml-[60px]
    ${(p) => (p.$backgroundColor === 'kiefGreen' ? ' lg:ml-auto' : null)}
    
`;

export const ContentContainer = tw.div`
    flex 
    items-center 
    sm:w-[310px]
    md:w-full
    justify-center 
    sm:gap-0
    md:gap-2`;
