import { useDispatch } from 'react-redux';
import CartSnackbar from 'components/organisms/global/snack-bar/cart-snackbar';
import useDispensary from 'helpers/hooks/use-dispensary';
import { ICartItem } from 'helpers/types';
import { TProductDetails } from 'helpers/types/organisms/listing/product-details';
import { useAppSelector } from 'redux/hooks';
import { addItemToCart, createCart, removeCartItem, updateCartItemQuantity } from 'redux/reducers/product-cart';
import { RootState } from 'redux/store';

export enum SnackBarMessages {
  ITEM_ADDED_TO_CART = 'Item added to the cart',
  ITEM_UPDATED_IN_CART = 'Item updated in the cart',
  ITEM_REMOVED_FROM_CART = 'Item removed from the cart',
}

export const useCartHandler = (props: TProductDetails, pvid: string, triggeredFrom: string) => {
  const dispatch = useDispatch();
  const cart = useAppSelector((state: RootState) => state.cart);
  const { openSnackbarHandler } = CartSnackbar({});
  const existingCart = cart && cart.carts[`${props.storeId}`];
  const existingItems = existingCart?.items || {};
  const existingItem: ICartItem = existingItems[pvid] || { product_id: null, quantity: 0, variant_id: '' };
  const { currentDispensary } = useDispensary();
  const menuItems = currentDispensary?.dispensaries?.menusCollection?.items || [];
  let fullfillmentType;
  if (menuItems?.length) {
    const foundMenu = menuItems?.find((item) => item?.menuSourceId === props?.storeId?.toString());
    fullfillmentType = foundMenu ? foundMenu?.menuFulfillmentType : menuItems[0]?.menuFulfillmentType;
  }
  const handleCart = async ({
    selectedQuantity,
    setShowSnackBar,
    onClose,
    setSelectedQuantity,
  }: {
    selectedQuantity: number;
    setShowSnackBar: (show: boolean) => void;
    onClose?: () => void;
    setSelectedQuantity: (number: number) => void;
  }) => {
    try {
      let cartData: any = {};
      let snackbarContent = '';
      if (existingCart) {
        cartData = existingCart;
      } else {
        const data = await (dispatch as any)(
          createCart({ store_id: props.storeId, fullfillmentType: fullfillmentType?.toLowerCase() || 'pickup' }),
        );
        cartData = data.payload;
        if (data?.type?.includes('rejected')) {
          throw new Error(data.payload);
        }
      }
      if (cartData.items && cartData.items[pvid] && selectedQuantity !== 0) {
        const data = await (dispatch as any)(
          updateCartItemQuantity({
            cartUUID: cartData.cart_uuid,
            pvid: pvid,
            quantity: selectedQuantity,
          }),
        );
        if (data?.type?.includes('rejected')) {
          throw new Error(data.payload);
        }
        snackbarContent = SnackBarMessages.ITEM_UPDATED_IN_CART;
      } else if (cartData.items[pvid] && selectedQuantity === 0) {
        const data = await (dispatch as any)(
          removeCartItem({
            cartUUID: cartData.cart_uuid,
            pvid: pvid,
          }),
        );
        if (data?.type?.includes('rejected')) {
          throw new Error(data.payload);
        }
        if (Object.keys(cartData.items).length === 1) {
          dispatch({
            type: 'cart/clearCart',
            payload: {
              store_id: props.storeId,
            },
          });
        }
        setSelectedQuantity(1);
        snackbarContent = SnackBarMessages.ITEM_REMOVED_FROM_CART;
      } else {
        const data = await (dispatch as any)(
          addItemToCart({
            cartUUID: cartData.cart_uuid,
            pvid: pvid,
            quantity: selectedQuantity,
            addCartEvent: {
              placement: props.placement + 1,
              product_position: props.position + 1 || 1,
              product_id: `${props.productId}`,
              product_name: `${props.strain}`,
              product_brand: `${props.brand}`,
              product_category: `${props.kind}`,
              product_lineage: props.tags.join(', '),
              product_weight: `${props.defaultWeightSelect}`,
              product_actual_price: `${Boolean(props.price) ? props.price : props.originalPrice}`,
              product_quantity: selectedQuantity,
              product_offer_applied: `${props.offerText}`,
              product_discounted_price: `${Boolean(props.price) ? props.originalPrice : 0}`,
              add_to_cart_trigger: triggeredFrom,
            },
          }),
        );
        if (data?.type?.includes('rejected')) {
          throw new Error(data.payload);
        }
        snackbarContent = SnackBarMessages.ITEM_ADDED_TO_CART;
      }
      openSnackbarHandler(snackbarContent);
      if (onClose) {
        onClose();
      }
    } catch (error) {
      openSnackbarHandler(error.message || 'An error occurred');
      setShowSnackBar(true);
    }
  };

  return { existingItem, handleCart };
};
