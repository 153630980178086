import { useEffect, useState } from 'react';
import { Typography } from 'components/atoms/typography';
import MinusIcon from 'public/icons/figma/minus.svg';
import PlusIcon from 'public/icons/figma/plus.svg';
import QuantityButton from './quantity-button';
import { ButtonContainer } from './styles';

interface IProps {
  maxLimit?: number;
  onChange?(value: number): void;
  quantityContainerStyle?: string;
  initialCount?: number;
  existingItemQuantity?: number;
}

const QuantitySelector = ({
  maxLimit = 10,
  onChange,
  quantityContainerStyle,
  initialCount,
  existingItemQuantity,
}: IProps) => {
  const [count, setCount] = useState(1);
  const newCounts = initialCount || count;
  useEffect(() => {
    if (existingItemQuantity) {
      setCount(0);
    } else if (initialCount !== undefined) {
      setCount(initialCount);
    } else {
      setCount(newCounts);
    }
  }, [existingItemQuantity, initialCount, newCounts]);
  const increment = () => {
    if (newCounts < maxLimit) {
      const newCount = Math.min(newCounts + 1, maxLimit);
      setCount(newCount);
      if (onChange) {
        onChange(newCount);
      }
    }
  };
  const decrement = () => {
    const newCount = Math.max(newCounts - 1, 0);
    setCount(newCount);
    if (onChange) {
      onChange(newCount);
    }
  };
  return (
    <ButtonContainer $style={quantityContainerStyle}>
      <QuantityButton icon={<MinusIcon data-testid="minus-icon" />} onClick={decrement} disabled={count === 1} />
      <Typography variant="body-small-bold" className="!text-grey-500">
        {newCounts}
      </Typography>
      <QuantityButton
        icon={<PlusIcon data-testid="plus-icon" />}
        onClick={increment}
        disabled={newCounts === maxLimit}
      />
    </ButtonContainer>
  );
};

export default QuantitySelector;
