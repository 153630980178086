import tw from 'tailwind-styled-components';

export const SourcesContainer = tw.div`
w-full bg-grey-100 px-5  lg:px-[60px] py-10 lg:py-[88px]
`;

export const SourcesBody = tw.div`
grid grid-flow-row-dense grid-cols-1 lg:grid-cols-12 lg:grid-rows-12 gap-6 lg:gap-0
`;

export const SourcesTitleBox = tw.div`
lg:col-span-3
`;

export const SourcesContent = tw.div`
lg:col-span-9
`;

export const SourcesCardsList = tw.div`
    w-full 
    [&_div.slick-track]:flex
    [&_div.slick-track]:items-start
    [&_div.slick-track]:justify-start
    [&_div.slick-track]:gap-2.5
    [&_div.slick-slide]:grid
    [&_div.slick-slide]:gap-2
    md:[&_div.slick-track]:gap-5
    2xl:[&_div.slick-track]:gap-6
`;

export const SourcesShowMore = tw.div`
pt-8 float-left lg:float-right flex flex-row gap-5 lg:gap-6 2xl:gap-8 items-center
`;
