import tw from 'tailwind-styled-components';

export const BannerHelpContainer = tw.div`
    relative
    flex
    flex-col
    lg:gap-8
    lg:p-10
    items-start
    w-auto
    lg:max-w-[888px]
    h-auto
    border-[1px]
    border-grey-300
    border-solid
    rounded-[6px]
    mb-20
    md:mb-0
    mx-5
    md:mx-0
    border-opacity-50
`;

export const BannerHelpInnerContainer = tw.div`
    flex
    flex-col
    px-[20px]
    pt-[24px]
    pb-[40px]
    lg:gap-8
    lg:p-0
`;

export const BannerHelpTextContainer = tw.div`
    w-auto
    h-auto
    flex
    flex-col
    pb-[24px]
    lg:gap-4
    lg:pb-0
`;

export const BannerHelpImage = tw.img`
    absolute
    bottom-0
    right-0
    lg:bottom-10
    lg:right-10
    h-[112px]
    w-[148px]
`;
