import { ReactNode } from 'react';

export type AnchorElement = React.AnchorHTMLAttributes<HTMLAnchorElement>;
export type DivElement = React.HTMLAttributes<HTMLDivElement>;
export type HeadingElement = React.HTMLAttributes<HTMLHeadingElement>;
export type SvgElement = React.HTMLAttributes<HTMLOrSVGElement>;

export interface IContentfulImage {
  description?: string;
  alt?: string;
  title?: string;
  url?: string;
  height?: number;
  width?: number;
}
export interface IDispensaryTiming {
  dayToDisplay?: string;
  day: string;
  hours: string;
  fromMedical?: string;
  toMedical?: string;
  from?: string;
  to?: string;
}

export enum CartErrors {
  CREATE_CART_ERROR = 'Failed to create cart',
  ADD_ITEM_CART_ERROR = 'Failed to add items to the cart',
  UPDATE_ITEM_CART_ERROR = 'Failed to update items in the cart',
  CART_LIMIT_ERROR = 'Maximum Limit Reached',
  REMOVE_ITEM_CART_ERROR = 'Failed to remove items from the cart',
}

export interface IDispensaryInfoProps {
  image: IContentfulImage;
  title: string;
  timing: IDispensaryTiming[] | null;
  address: { text: string; url: string };
  phoneNumber: string;
  contactUs: { text: string; url: string };
  cta?: { text: string; url: string; sendSegmentTrackEvent?: boolean };
  state?: string;
  email?: string;
  fax?: string;
  _geoloc?: { lat: number; lng: number };
  menuDetails?: IMenuDetailsProps[];
  slug?: string;
  cms_tags?: string[];
}

export interface IDispensaryCardTiming {
  day: string[];
  from: string;
  to: string;
  fromMedical: any;
  toMedical: any;
  customLabel: any;
}

export interface IDispensaryCards {
  image: IContentfulImage;
  timing: IDispensaryCardTiming[] | null;
  mobile_image: IContentfulImage;
  title: string;
  address: { text: string };
  phoneNumber: string;
  state?: string;
  _geoloc?: { lat: number; lng: number };
  slug?: string;
}

export interface IMenuDetailsProps {
  isDefault: string;
  menuFulfillmentType: string;
  menuType: string;
  slug: string;
  linkedFrom?: {
    pageMenuCollection: {
      items: Array<{
        slug: string;
      }>;
    };
  };
  menuId: string;
  menuUrl?: string;
}
export type TFAQItem = {
  question: string;
  answer: any;
};
export interface ICookieConfig {
  name: string;
  possible_values: any;
  max_days: number;
}

export type TContenfulImageProp = {
  desktopImage?: TContenfulImageProp;
  mobileImage?: TContenfulImageProp;
  thumbnailImage?: TContenfulImageProp;
  url: string;
  width?: number;
  height?: number;
  title?: string;
  alt?: string;
};

export type TBreadCrumbItem = {
  label?: string;
  path?: string;
};

export enum PopUpType {
  JOIN_MAILING_LIST = 'Join Mailing List',
  SCHEDULING = 'Scheduling',
  RESERVATION = 'Reservation',
  SMOKEY = 'Smokey',
  EVENT_LIST = 'Event List',
}
export enum VariantType {
  SPACED_SECONDARY = 'Spaced Secondary',
  SPACED_PRIMARY = 'Spaced Primary',
  FULL = 'Full',
  DEALS = 'Deals',
  SPECIAL_OFFER = 'Special Offer',
  REWARDS = 'Rewards',
  TEXT_AREA = 'Text Area',
  VIDEO = 'Video',
}

export enum CaseVariants {
  BANNER_C09_A_GENERAL = 'Banner C09 A General',
  BANNER_C09_RIGHT_IMAGE = 'Banner C09 Right Image',
  BANNER_C09_LEFT_IMAGE = 'Banner C09 Left Image',
  BANNER_C09_A_TEXT_BUTTON = 'Banner C09 A Text Button',
  BANNER_C09_B_EXTENDED_IMAGE = 'Banner C09 B Extended Image',
  BANNER_C10_STATEMENT = 'Banner C10 Statement',
  BANNER_C11_A_DEALS = 'Banner C11 A Deal',
  BANNER_C11_B_SPECIAL_OFFER = 'Banner C11 B Special Offer',
  BANNER_C11_C_REWARDS = 'Banner C11 C Rewards',
  BANNER_C11_D_TEXT_AREA = 'Banner C11 D Textarea',
  BANNER_C11_E_VIDEO = 'Banner C11 E Video',
  BANNER_C12_HELP = 'Banner C12 Help',
  BANNER_C08_PRODUCT_IMAGE = 'Banner C08 Product Image',
  CART_LIMIT_ERROR = 'Maximum Limit Reached',
}
export interface ISmokeEasyModalData {
  popupHeading: string;
  popupDescription: IRichTextData;
  buttonImage: IContentfulImage;
  btns: IDispensaryInfoMenuButton[];
}

export interface IReservationModalData {
  popupHeading: string;
  popupDescription: IRichTextData;
  popupIFrameUrl: string;
}

export interface IEventCalendarModalData {
  popupHeading: string;
  popupDescription: IRichTextData;
  popupIFrameUrl: string;
}
export interface IDispensaryInfoMenuButton {
  text: string;
  url: string;
  selectMenuToLinkForProduct?: boolean;
  isExternalLink?: boolean;
  popUpType?: PopUpType;
}
export interface ICta {
  title: string;
  longUrl?: string;
}
export interface ICtaBtn {
  url: string;
  longUrl: string;
  title: string;
  openInNewTab: boolean;
}
export interface IRichTextData {
  json: any;
  links?: Record<any, any>;
}

export interface ICartItem {
  product_id: number;
  variant_id: string;
  quantity: number;
}

export interface ICart {
  items: { [key: string]: ICartItem };
  cart_uuid: string;
  subTotal: number;
  discountPrice: number;
}

export interface IArticleCard {
  publishDate: string;
  category: string;
  listItemImages: TContenfulImageProp;
  listItemImage?: TContenfulImageProp;
  listItemTitle: string;
  listItemDescription: IRichTextData;
  ctaText?: string;
  slug: string;
  bgColor?: string;
  onLoad?: (data: any) => void;
  host?: string;
  style?: object;
}

export interface IMarkDownLinkRenderer {
  href?: string;
  children: ReactNode;
  node: Record<any, any>;
}

export type COMPONENT_TYPE = 'banner_image' | 'customer_center';
export type ROLE_TYPE = 'heading' | 'drawer_heading' | 'description';

export type TAdditionalContext = {
  testId?: string;
  componentType?: COMPONENT_TYPE;
  tagRole?: ROLE_TYPE;
  componentIndex?: number;
  roleIndex?: number;
  skipPageType?: boolean;
  // can be expanded
};
export type TDetailForMap = {
  id?: string;
  title: string;
  lat: string;
  lng: string;
  icon: string;
  uri: string;
  address?: string;
  website?: string;
  email?: string;
  phoneNumber?: string;
  customDistance?: string;
  menuBtns?: Record<string, any>[];
  openingTime?: IDispensaryTiming[];
  heroImage?: IContentfulImage;
  mobileHeroImage?: IContentfulImage;
  fax?: string;
  tags?: string[];
};

export interface IGeoLoc {
  lat: number;
  lng: number;
}

export interface IListingDetail {
  id?: string;
  title: string;
  lat: string;
  lng: string;
  icon: string;
  uri: string;
  openingTime?: IDispensaryTiming[];
  address?: string;
  mapUrl?: string;
  phoneNumber?: string;
  description?: string;
  menuBtns?: Array<{ name: string; url: string; isExternalLink: boolean; isDefault: string }>;
  website?: string;
  phone?: string;
  fax?: string;
  email?: string;
  _geoloc?: IGeoLoc;
  customDistance?: string;
  heroImage?: IContentfulImage;
  mobileHeroImage?: IContentfulImage;
  tags?: string[];
}

export enum MenuSourceType {
  iheartjane = 'iheartjane',
  external = 'external',
  dutchie = 'dutchie',
  gti = 'gti',
}

export interface IAllTabs {
  tab: string;
  index: number;
}

export enum MapType {
  dispensary = 'dispensaryMap',
  provider = 'providerMap',
}

export interface IZipcodeBasedGeoData {
  latitude: number;
  longitude: number;
  state: string;
  zip: string;
}

export enum TSelectedSubItem {
  OVERVIEW = 'OVERVIEW',
  PROFILE = 'PROFILE',
  ORDERS = 'ORDERS',
  ORDER_DETAILS = 'ORDER_DETAILS',
  REWARDS = 'REWARDS',
  HOME_DISPENSARY = 'HOME_DISPENSARY',
}

export enum AccountUrl {
  OVERVIEW = '/account',
  PROFILE = '/account/profile',
  ORDERS = '/account/orders',
  REWARDS = '/account/rewards',
  HOME_DISPENSARY = '/account/my-dispensary',
}

export type ORDER_STATUS = 'Verifying' | 'Preparing' | 'Ready' | 'Complete';

export enum ORDER_STATUS_MAPPING {
  pending = 'Unsubmitted',
  verification = 'Verifying',
  staff_member_review = 'Verifying',
  preparing = 'Preparing',
  delivery_started = 'Ready',
  delivery_finished = 'Ready',
  ready_for_pickup = 'Ready',
  finished = 'Complete',
  with_review = 'Complete',
  cancelled = 'Cancelled',
  dismissed = 'Cancelled',
  finished_without_review = 'Complete',
}

export type TDispensaryDrawerOpener = 'HOME_DISPENSARY' | 'GEO_LOCATION' | 'CHANGE_LOCATION';

export const enum HeroContentVariant {
  LOGO = 'Logo',
  CLIPPED_IMAGE = 'Clipped Image',
  NO_OVERLAP_IMAGE = 'No Overlap Image',
}
