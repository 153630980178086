import { useEffect } from 'react';
import usePagination from '../../../helpers/hooks/usePagination';
import { Typography } from '../typography';
import { TPaginationProps, Variant } from './pagination.types';
import {
  Container,
  IconCard,
  IconContainer,
  NextIcon,
  PDPCarouselPaginationCardDesktop,
  PDPCarouselPaginationCardMobile,
  PDPCarouselPaginationDesktopContainer,
  PrevIcon,
  ProgressBarIndicator,
  ProgressBarStatus,
} from './style';

const Pagination = (props: TPaginationProps & { className?: string }) => {
  const { count, variant, isVisible = true, color, slickCurrentIndex, className } = props;
  const itemsPerPage = variant === 'large' ? props.itemsPerPage : 1;
  const { currentPage, next, setCurrentPageTo, prev, totalPage, startIndex, endIndex } = usePagination(
    count,
    itemsPerPage,
    variant !== 'center-carousel' && props.onPageChange,
  );

  useEffect(() => {
    if (variant !== 'center-carousel' && slickCurrentIndex !== undefined && slickCurrentIndex !== null) {
      const slickCurrentPage = slickCurrentIndex + 1;
      if (slickCurrentPage !== currentPage) {
        setCurrentPageTo(slickCurrentPage);
      }
    }
  }, [slickCurrentIndex]);

  const isWhite = color === 'white';
  const progressBarValue = Math.ceil((currentPage / totalPage) * 100);

  const onPrevClick = () => {
    if (currentPage === 1 || props.disableButton) return;
    if (props.onNextPrevClick) props.onNextPrevClick(currentPage - 2);
    else prev();
  };

  const onNextClick = () => {
    if (currentPage === totalPage || props.disableButton) return;
    if (props.onNextPrevClick) props.onNextPrevClick(currentPage);
    else next();
  };

  const prevIconProps = { $disabled: currentPage === 1, onClick: onPrevClick };
  const nextIconProps = { $disabled: currentPage === totalPage, onClick: onNextClick };

  if (itemsPerPage >= count) return null;

  const getPaginationVariant = (variant: Variant, props) => {
    let html;
    switch (variant) {
      case 'small':
      case 'large':
        html = (
          <Container className={className}>
            <ProgressBarStatus data-testid="progressbar-status" $white={isWhite} $variant={variant}>
              <Typography variant="body-small">
                {`${
                  itemsPerPage === 1
                    ? currentPage
                    : `${endIndex === startIndex + 1 ? startIndex + 1 : `${startIndex + 1}-${endIndex}`}`
                } of ${count}`}
              </Typography>
            </ProgressBarStatus>
            <ProgressBarIndicator
              data-testid="progressbar-indicator"
              $variant={variant}
              $white={isWhite}
              value={progressBarValue}
            />
            <IconContainer>
              <PrevIcon data-testid="prev-icon" $color={color} $variant={variant} {...prevIconProps} />
              <NextIcon data-testid="next-icon" $color={color} {...nextIconProps} />
            </IconContainer>
          </Container>
        );
        break;
      case 'pill-desktop':
        html = (
          <PDPCarouselPaginationDesktopContainer className={className}>
            <PDPCarouselPaginationCardDesktop>
              <ProgressBarStatus data-testid="progressbar-status">
                <Typography variant="body-small">{`${currentPage} of ${count}`}</Typography>
              </ProgressBarStatus>
              <ProgressBarIndicator
                data-testid="progressbar-indicator"
                $variant="pill-desktop"
                $white={isWhite}
                value={progressBarValue}
              />
            </PDPCarouselPaginationCardDesktop>
            <IconContainer>
              <IconCard $mr={4}>
                <PrevIcon data-testid="prev-icon" $color="leaf" $variant="pill-desktop" {...prevIconProps} />
              </IconCard>
              <IconCard>
                <NextIcon data-testid="next-icon" $color="leaf" {...nextIconProps} />
              </IconCard>
            </IconContainer>
          </PDPCarouselPaginationDesktopContainer>
        );
        break;
      case 'pill-mobile':
        html = (
          <PDPCarouselPaginationCardMobile $className={props.buttonClass}>
            <PrevIcon data-testid="prev-icon" $color={color} {...prevIconProps} />
            <ProgressBarStatus
              data-testid="progressbar-status"
              $widthAuto={count > 9}
              $variant="pill-mobile"
              $white={isWhite}
            >
              <Typography variant="body-small">{`${currentPage} of ${count}`}</Typography>
            </ProgressBarStatus>
            <NextIcon data-testid="next-icon" $color={color} {...nextIconProps} />
          </PDPCarouselPaginationCardMobile>
        );
        break;
      case 'center-carousel':
        html = (
          <Container className={className}>
            <Typography variant="body-small" className={`mr-5 !text-grey-400 lg:mr-8`}>
              {`${props?.additionalText ? props?.additionalText + ' ' : ''}` + `${props.currentPage} of ${props.count}`}
            </Typography>

            <ProgressBarIndicator
              data-testid="progressbar-indicator"
              $variant="center-carousel"
              value={Math.ceil((props.currentPage / props.count) * 100)}
              $white={isWhite}
            />
          </Container>
        );
        break;
      default:
        html = null;
        break;
    }
    return html;
  };

  return isVisible && getPaginationVariant(variant, props);
};

export default Pagination;
