import { useEffect, useRef, useState } from 'react';

const useIntersectionObserver = (callback?: () => void, options?: any) => {
  const targetRef = useRef(null);
  const [hasTriggered, setHasTriggered] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio >= 0.5 && !hasTriggered) {
          callback();
          setHasTriggered(true);
        } else if (entry.intersectionRatio < 0.1 && hasTriggered) {
          setHasTriggered(false);
        }
      },
      { threshold: [0.1, 0.5], ...options },
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer?.unobserve(targetRef.current);
      }
    };
  }, [callback, options, hasTriggered]);

  return targetRef;
};

export default useIntersectionObserver;
