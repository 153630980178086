import { Fragment, useState } from 'react';
import { Typography } from 'components/atoms/typography';
import FaqAccordion from 'components/molecules/accordions/faq-accordion';
import { BannerHelpComponent } from 'components/organisms/banners/banner-help';
import BannerImage from 'components/organisms/banners/banner-image';
import BannerInformation from 'components/organisms/banners/banner-information';
import { BannerProduct } from 'components/organisms/banners/banner-product';
import BannerStatement from 'components/organisms/banners/banner-statement';
import { bannerTrack } from 'helpers/analytics/banner-track';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { CaseVariants, VariantType } from 'helpers/types';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import CommonImages from '../../images';
import Sources from '../../sources';
import {
  BannerContainer,
  BottomPaddingClass,
  FaqWrapper,
  ImageDescriptionStyle,
  ImageGalleryContainer,
  ImageSectionContainer,
  SourcesContainer,
} from './style';

export const FaqSection = (entry) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(null);

  const handleAccordionClick = (index: number) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <FaqWrapper>
      {entry.faqsCollection.items.map((faqItem, index) => (
        <div key={index}>
          <FaqAccordion
            key={index}
            data={{
              question: faqItem.question,
              answer: faqItem.answer,
            }}
            index={index}
            shouldOpen={openAccordionIndex === index}
            handleOpen={handleAccordionClick}
            faqWidth={false}
          />
        </div>
      ))}
    </FaqWrapper>
  );
};

export const BannerSection = (data: any, className?: string, isTablet?: boolean, index = 1, host?: string) => {
  if (!data) return <Fragment />;
  const ctaData = data?.ctaCollection?.items?.[0];
  const handleSegmentEvent = (type?: string) => {
    const bannerClickedData = {
      placement: data?.tasticPlacementNumber + 1 || index + 1,
      banner_position: 1,
      banner_internal_name: data?.internalName,
      banner_title: data?.title,
      banner_image_main: isTablet ? data?.image?.url : data?.mobileImage?.url || data?.image?.url,
      banner_image_additional: isTablet
        ? data?.additionalImage?.desktopImage?.url
        : data?.additionalImage?.mobileImage?.url || data?.additionalImage?.desktopImage?.url,
      banner_image_overlay: isTablet
        ? data?.additionalImage?.desktopImage?.url
        : data?.additionalImage?.mobileImage?.url || data?.additionalImage?.desktopImage?.url,
      banner_type: data?.bannerType,
      link_name: data?.ctaCollection?.items[0]?.title || '',
      link_type: 'hyperlink',
    };
    bannerTrack(type, bannerClickedData);
  };
  const commonBannerData = {
    title: data?.title,
    description: data?.description,
    subTitle: data?.subTitle,
    image: {
      desktopImage: {
        ...data?.image,
      },
      mobileImage: data?.mobileImage,
    },
    additionalImage: data?.additionalImage,
    overlayImage: data?.overlayImage,
    ctaCollection: data?.ctaCollection?.items?.[0],
    host: data?.host,
    video: data?.videoEmbed,
    handleSegmentEvent: (type?: string) => handleSegmentEvent(type),
  };

  const renderBanner = () => {
    switch (data?.bannerType) {
      case CaseVariants.BANNER_C09_LEFT_IMAGE:
        return (
          <BannerImage
            richTextCustom={true}
            imagePlacement="left"
            {...commonBannerData}
            variant={VariantType.SPACED_SECONDARY}
          />
        );
      case CaseVariants.BANNER_C09_A_GENERAL:
      case CaseVariants.BANNER_C09_RIGHT_IMAGE:
        return <BannerImage richTextCustom={true} {...commonBannerData} variant={VariantType.SPACED_SECONDARY} />;
      case CaseVariants.BANNER_C09_A_TEXT_BUTTON:
        return <BannerImage richTextCustom={true} {...commonBannerData} variant={VariantType.SPACED_PRIMARY} />;
      case CaseVariants.BANNER_C09_B_EXTENDED_IMAGE:
        return <BannerImage richTextCustom={true} {...commonBannerData} variant={VariantType.FULL} />;
      case CaseVariants.BANNER_C10_STATEMENT:
        return <BannerStatement {...commonBannerData} />;
      case CaseVariants.BANNER_C11_A_DEALS:
        return <BannerInformation variant={VariantType.DEALS} {...commonBannerData} />;
      case CaseVariants.BANNER_C11_B_SPECIAL_OFFER:
        return <BannerInformation variant={VariantType.SPECIAL_OFFER} {...commonBannerData} />;
      case CaseVariants.BANNER_C11_C_REWARDS:
        return <BannerInformation variant={VariantType.REWARDS} {...commonBannerData} />;
      case CaseVariants.BANNER_C11_D_TEXT_AREA:
        return <BannerInformation variant={VariantType.TEXT_AREA} {...commonBannerData} />;
      case CaseVariants.BANNER_C11_E_VIDEO:
        return <BannerInformation variant={VariantType.VIDEO} {...commonBannerData} />;

      case CaseVariants.BANNER_C08_PRODUCT_IMAGE:
        return (
          <BannerProduct
            variant="image"
            bgColor="kief"
            {...commonBannerData}
            image={data?.image?.url}
            productImageProps={{
              additionalImage: commonBannerData?.additionalImage?.desktopImage?.url,
              overlayImage: commonBannerData?.overlayImage?.desktopImage,
              ctaText: commonBannerData?.ctaCollection?.title,
              ctaLink: getPathMenuFiltersLink(commonBannerData?.ctaCollection?.longUrl),
            }}
            host={host}
          />
        );
      case CaseVariants.BANNER_C12_HELP:
        return (
          <BannerContainer>
            <div className="md:w-[888px]">
              <BannerHelpComponent
                title={data?.title}
                subtitle={data?.description}
                cta={ctaData}
                imageUrl={data?.image?.url}
                host={data?.host}
                handleSegmentEvent={handleSegmentEvent}
              />
            </div>
          </BannerContainer>
        );
      case 'Other':
        return <Fragment />;
      default:
        return <Fragment />;
    }
  };

  return (
    <div data-testid="section-banner" className={`${className}`}>
      {renderBanner()}
    </div>
  );
};

export const SourcesSection = (entry) => {
  return (
    <SourcesContainer>
      <Sources title="Sources" data={entry?.sourcesCollection?.items} data-testid="section-sources" />
    </SourcesContainer>
  );
};

export const ImageSection = (entry) => {
  const [isTablet] = useMediaQuery(tablet);
  const Imagedata = isTablet ? entry?.desktopImage : entry?.mobileImage;
  const Imagedescription = isTablet ? entry?.desktopImage?.description : entry?.mobileImage?.description;
  const Type = entry?.alignment == 'Full' ? 'big' : 'small';
  return (
    <ImageSectionContainer $Type={Type} data-testid={'imagesection'} className={BottomPaddingClass}>
      <CommonImages image={Imagedata} type={Type} alignment={true} />
      <Typography variant="body-small" className={ImageDescriptionStyle}>
        {Imagedescription}
      </Typography>
    </ImageSectionContainer>
  );
};

export const ImageGallerySection = (entry) => {
  const imageArray = entry?.imagesCollection?.items.map((item) => item);

  return (
    <ImageGalleryContainer data-testid={'gallery'} className={BottomPaddingClass}>
      <CommonImages image={imageArray} type="grid" />
    </ImageGalleryContainer>
  );
};
