import Image from 'next/image';
interface TProps {
  data: Record<string, any>;
  dataIndex: any;
  isCenterSlide: boolean;
}
export const CarouselImage = ({ data, dataIndex, isCenterSlide }: TProps) => {
  const { cover, title, width, height } = data[dataIndex] || {};

  return (
    <div
      key={dataIndex}
      className={`${
        !isCenterSlide ? 'brightness-50' : 'current'
      } !relative h-full w-full [&_span]:!relative [&_span]:!h-full`}
      data-testid="carousel-image-container"
    >
      <Image
        loader={(options) => options.src}
        alt={title}
        src={cover}
        layout="responsive"
        width={width}
        height={height}
        objectFit="cover"
        className="rounded-md"
        data-testid="carousel-image"
      />
    </div>
  );
};
