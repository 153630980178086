// IconButton.tsx
import { CommonButton, IButtonProps } from '../common-buttons/button';

export const IconButton = ({ children, className, size, ...buttonProps }: IButtonProps) => {
  return (
    <CommonButton
      {...buttonProps}
      className={`flex ${className} ${size === 'lg' ? '!p-[12px] lg:!p-[20px]' : '!p-[8px] lg:!p-[12px]'}`}
    >
      {children}
    </CommonButton>
  );
};
