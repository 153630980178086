import { FormikProps, FormikValues } from 'formik';
import { SelectDefaultProps, SelectDropdown } from 'components/atoms/inputs/select';
interface FormikSelectProps {
  fieldName?: string;
  value?: string;
  values: FormikValues;
  errors?: any;
  touched?: any;
  setFieldValue?: (field: string, value: string) => any;
  setFieldTouched?: (field: string, value: boolean) => any;
}

export const FormikSelect = ({
  fieldName,
  label,
  items,
  value,
  setFieldValue,
  onChange,
  setFieldTouched,
  errors,
  touched,
  values,
  required,
  disabled,
  ...props
}: FormikSelectProps & SelectDefaultProps) => {
  const invalid = errors[fieldName] && touched[fieldName] && values[fieldName] === '';
  return (
    <SelectDropdown
      label={label}
      required={required}
      disabled={disabled}
      items={items}
      defaultValue={values[fieldName] || value}
      onChange={(value) => {
        setFieldValue?.(fieldName, value);
        onChange?.(value);
        if (!touched[fieldName]) setFieldTouched(fieldName, true);
      }}
      error={invalid ? true : false}
      errorMessage={errors[fieldName]}
      {...props}
    />
  );
};

export const SelectStateDropdown = (
  props: FormikProps<SelectDefaultProps & FormikSelectProps> & SelectDefaultProps & FormikSelectProps,
) => {
  return <FormikSelect label="Select state" fieldName="state" {...props} />;
};

export const SelectDispensaryDropdown = (
  props: FormikProps<SelectDefaultProps & FormikSelectProps> & SelectDefaultProps & FormikSelectProps,
) => {
  return <FormikSelect label="Select dispensary" fieldName="dispensary" {...props} />;
};
