import React from 'react';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TContenfulImageProp } from 'helpers/types';
import { mediumDesktop, tablet } from 'helpers/utils/screensizes';
import BigImage from './big-image';
import GridImage from './grid-image';
import SmallImage from './small-image';
type TProps = {
  image: TContenfulImageProp | TContenfulImageProp[];
  title?: string;
  type: 'big' | 'small' | 'grid';
  alignment?: boolean;
};
const CommonImages = ({ image, title, type, alignment }: TProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const [isMediumDesktop] = useMediaQuery(mediumDesktop);
  if (Array.isArray(image) && type === 'grid') {
    return <GridImage image={image} />;
  } else if (typeof image === 'object' && (type === 'big' || type === 'small')) {
    if (type === 'big') {
      return (
        <BigImage
          image={Object(image)}
          title={title}
          alignment={alignment}
          isTablet={isTablet}
          isMediumDesktop={isMediumDesktop}
        />
      );
    } else {
      return (
        <SmallImage image={Object(image)} alignment={alignment} isTablet={isTablet} isMediumDesktop={isMediumDesktop} />
      );
    }
  }
};

export default CommonImages;
