import tw from 'tailwind-styled-components';

interface IRihcTextImageAlignementProps {
  $alignment?: boolean;
  $type?: string;
}

export const BigImageContainer = tw.div<IRihcTextImageAlignementProps>`
     ${(props) => (props.$alignment ? '' : 'grid place-content-center w-full max-w-[100%]')} 
`;

export const SmallImageContainer = tw.div<IRihcTextImageAlignementProps>`
    ${(props) => (props.$alignment ? ' ' : 'grid place-content-center w-full max-w-[100%]')}
`;

export const ImageGridContainer = tw.div`
    grid
    grid-cols-2 
    gap-y-3 
    gap-x-[10px] 
    md:grid-cols-3 
    md:gap-6 
`;
