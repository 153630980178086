import { BannerStatementVariantType, TBannerStatementProps } from 'helpers/types/organisms/banners/banner-statement';
import { BannerStatementDefault } from './banner-content-default';
import BannerStatementLong from './banner-content-long';

const BannerStatement = (props: TBannerStatementProps) => {
  switch (props?.variant) {
    case BannerStatementVariantType.long:
      return <BannerStatementLong {...props} />;
    default:
      return <BannerStatementDefault {...props} />;
  }
};
export default BannerStatement;
