import Image, { ImageProps } from 'next/image';
import tw from 'tailwind-styled-components';
import { IIconWithTextButtonProps, IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { TProps as TextProps, Typography } from 'components/atoms/typography';
import styles from './styles.module.css';

export const BannerDealsButton = tw(IconWithTextButton)<IIconWithTextButtonProps>`
    !p-0
    lg:!p-0
`;

export const BannerDealsSubTitleText = tw(Typography)<TextProps>`
    pb-2
    md:pb-4
`;

export const BannerDealsTitleText = tw(Typography)<TextProps>`
    pb-4
    md:pb-6
`;

export const BannerDealsImg = tw(Image)<ImageProps>`
    h-full
    w-full
`;

export const BannerDealsContainer = tw.div`
    bg-kief
    grid
    md:grid-cols-3
`;

export const BannerDealsImage = tw.div`
    relative
    h-[134px]
    w-full
    md:h-[452px]
`;

export const BannerDealsBody = tw.div`
    ${() => styles['banner-deals-body']}
`;

export const BannerDealsContentWrapper = tw.div`
    ${() => styles['banner-deals-content-wrapper']}
`;

export const BannerDealsContent = tw.div`
    p-0
    md:px-[60px]
    md:py-[88px]
    col-span-3
`;

export const BannerDealsImageWrapper = tw.div`
    flex
    self-end
    justify-self-end
    mb-0
    md:-mb-[28%]
`;
