import tw from 'tailwind-styled-components';

export const BSDesktopContainer = tw.div`
    hidden 
    grid 
    bg-grey-100 
    grid-cols-1 
    grid-cols-6
`;

export const BSDesktopContent = tw.div`
    grid 
    grid-cols-5 
    col-span-5
`;

export const BSDesktopLeftImg = tw.div`
    flex 
    items-end 
    float-left
    2xl:pb-[76px]
`;

export const BSDesktopBody = tw.div`
    col-span-4 
    pt-[120px]
`;

export const BSDesktopIconImg = tw.div`
    pb-[64px] 
    flex 
    items-center 
    justify-center
`;

export const BSDestopTitle = tw.div`
    flex 
    items-center 
    justify-center 
    text-center 
    w-[88%]
`;

export const BSDesktopButton = tw.div`
    flex 
    items-center 
    justify-center
    pt-12
    pb-[120px] 
    col-start-2 
    col-end-6
`;

export const BSDesktopRightImgBox = tw.div`
    block
`;

export const BSDesktopRightImg = tw.div`
    float-right
`;

export const BSMobileContainer = tw.div`
    flex 
    lg:hidden 
    flex-wrap 
    w-full 
    relative 
    bg-grey-100 
`;

export const BSMobileLeftImg = tw.div`
    absolute 
    right-0
`;

export const BSMobileContent = tw.div`
    relative 
    py-[120px] 
    px-10
    z-10 
    w-full
`;

export const BSMobileIconImg = tw.div`
    flex 
    items-center 
    justify-center
`;

export const BSMobileTitle = tw.div`
    py-8 
    text-center 
    flex 
    items-center 
    justify-center
`;

export const BSMobileButton = tw.div`
    flex 
    items-center 
    justify-center
`;

export const BSMobileRightImg = tw.div`
    absolute 
    bottom-0  
    left-0
`;

export const QuotesSection = tw.div`
    w-full 
    grid 
    grid-rows-2
    col-span-4
    md:grid-flow-col 
    md:gap-[60px]
    gap-5
    justify-center
`;

export const QuotesBox = tw.div`
    flex
    flex-col
    md:self-start 
    sm:self-center
    gap-1
`;

export const QuotesText = tw.p`
    font-sans
    odd:text-primary-100 
    even:text-secondary-100 
    uppercase
    text-center 
    md:text-2xl 
    md:!leading-[26px] 
    sm:text-xl
    sm:!leading-[19.8px] 
    sm:mb-1 
    md:mb-1.5  
    font-medium
    tracking-[0.9px] 
    max39em:px-5
`;

export const BSQuoteWrapperDesktop = tw.div`
    col-span-4 
    flex 
    flex-col  
    items-center  
    justify-center 
    gap-10 
    py-[120px] 
    md:flex-row 
    md:gap-5 
    md:py-20  
    2xl:gap-[56px]  
    2xl:py-[160px]
`;

export const BSImageContainerDesktop = tw.div`
    flex 
    flex-col 
    items-center 
    2xl:h-[354px] 
    lg:h-[207px] 
    lg:min-w-[120px] 
    lg:max-w-[229px]
`;

export const BSQuoteWrapperMobile = tw.div`
    flex    
    flex-col 
    items-center 
    justify-center 
    gap-10
`;

export const BSImageContainerMobile = tw.div`
    flex 
    flex-row 
    items-center 
    h-[120px] 
    w-[252px]
`;
