import { useEffect, useState } from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';

interface WeightSelectorButtonProps {
  label: string;
  refinementValue?: string;
  size: 'sm' | 'lg';
  defaultSelected?: boolean;
  className?: string;
  onClick?: (select: { name: string; value: string }) => void;
  isMultiSelect?: boolean;
  disabled?: boolean;
}

export const WeightSelectorButton = ({
  label = 'label',
  size = 'sm',
  onClick,
  defaultSelected,
  className,
  refinementValue,
  isMultiSelect = true,
  disabled,
}: WeightSelectorButtonProps) => {
  const [selected, setSelected] = useState(defaultSelected);
  const onSelect = () => {
    const newState = !selected;
    if (onClick) onClick({ name: label, value: refinementValue });
    if (isMultiSelect) {
      setSelected(newState);
    }
  };

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const activedStyle = selected
    ? `
      !bg-leaf
      !font-bold
      !text-white
      !border-none
    `
    : `
      !border-grey-400/80 
      font-normal
      !text-grey-400
      [&_svg_path]:!fill-grey-400/80
      `;

  const currentStyle = disabled ? '' : activedStyle;
  const sizeStyle =
    size === 'sm'
      ? 'p-[12px_0px] md:!p-[8px_0px] min-w-[106px] h-10 md:h-[34px] md:min-w-[71px] xl:h-9 xl:min-w-[88px]'
      : '!py-3 xl:min-w-[178px] md:min-w-[144px] xl:!py-[14px] min-w-[120px] xl:h-12 md:h-[42px] h-10';

  return (
    <CommonButton
      size={size}
      color="green"
      button-type="secondary"
      className={`flex items-center justify-center rounded-md ${className} ${sizeStyle} ${currentStyle}`}
      onClick={onSelect}
      disabled={disabled}
    >
      {label}
    </CommonButton>
  );
};
