import React, { useRef, useState } from 'react';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import SourcesCard from 'components/molecules/cards/link-cards/without-image/sources-cards';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import {
  SourcesBody,
  SourcesCardsList,
  SourcesContainer,
  SourcesContent,
  SourcesShowMore,
  SourcesTitleBox,
} from './styles';

type TData = {
  title: string;
  description: string;
  url: string;
};

type TProps = {
  title: string;
  data: TData[];
  minNumberShow?: number;
};

const Sources = ({ title, data, minNumberShow = 3 }: TProps) => {
  const totalCards = data.length;
  const slickRef = useRef(null);
  const [slickCurrentIndex, setSlickCurrentIndex] = useState(0);
  const [isTablet] = useMediaQuery(tablet);
  const responseSlider = isTablet
    ? {
        slidesToShow: minNumberShow,
        slidesToScroll: minNumberShow,
        initialSlide: 0,
        rows: 1,
        afterChange: (x: number) => setSlickCurrentIndex(Math.ceil(x / minNumberShow)),
      }
    : {
        rows: minNumberShow,
        slidesToShow: 1,
        afterChange: (x: number) => setSlickCurrentIndex(Number(x)),
      };

  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    arrows: false,
    speed: 100,
    variableWidth: true,
    infinite: false,
    ...responseSlider,
  };
  const onChange = (p: any) => {
    if (isTablet) {
      slickRef.current.slickGoTo(p?.startIndex);
    } else {
      slickRef.current.slickGoTo(p?.page - 1);
    }
  };
  return (
    <SourcesContainer>
      <SourcesBody>
        <SourcesTitleBox>
          <Typography variant="h3">{title}</Typography>
        </SourcesTitleBox>
        <SourcesContent>
          <SourcesCardsList>
            <Slider {...settings} ref={slickRef}>
              {data?.map((item: TData, index) => (
                <SourcesCard {...item} key={index} />
              ))}
            </Slider>
          </SourcesCardsList>
          <SourcesShowMore>
            <Pagination
              count={totalCards}
              color="resin"
              variant="large"
              itemsPerPage={minNumberShow}
              onPageChange={onChange}
              slickCurrentIndex={slickCurrentIndex}
            />
          </SourcesShowMore>
        </SourcesContent>
      </SourcesBody>
    </SourcesContainer>
  );
};

export default Sources;
