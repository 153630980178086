import { IconCard, NextIcon, PrevIcon } from './style';

type TProps = {
  variant: 'left' | 'right';
  disabled?: boolean;
  onClick(): void;
  className?: string;
};

const Arrow = ({ variant, disabled = false, onClick, className }: TProps) => {
  const props = { $color: 'leaf' as const, $disabled: disabled, onClick: onClick, className };
  return variant === 'left' ? (
    <IconCard>
      <PrevIcon data-testid="prev-icon" {...props} />
    </IconCard>
  ) : (
    <IconCard>
      {' '}
      <NextIcon data-testid="next-icon" {...props} />
    </IconCard>
  );
};

export default Arrow;
