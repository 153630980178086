import { Typography } from 'components/atoms/typography';
import RatingStar from 'public/icons/figma/rating-star-filled.svg';
import { RatingReviewContainer } from './styles';
interface RatingReveiwProps {
  score?: number;
  text?: string;
  className?: string;
}

const RatingReview = ({ score, text, className = '' }: RatingReveiwProps) => {
  return (
    <RatingReviewContainer className={className}>
      {score && (
        <>
          <Typography variant="body-small-bold">{score}</Typography>
          <RatingStar />
        </>
      )}
      {text && <Typography variant="body-small">{text}</Typography>}
    </RatingReviewContainer>
  );
};

export default RatingReview;
