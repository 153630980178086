import tw from 'tailwind-styled-components';

type ProductBannerProps = {
  $variant: string;
};

export const BannerProductWrapper = tw.div<ProductBannerProps>`
    max-w-[1920px] 
    mx-auto
    px-6
    py-6 
    lg:h-[720px] 
    lg:px-10 
    lg:py-10 
    2xl:h-[952px] 
    2xl:px-[60px] 
    2xl:py-[64px]
     ${(p) => (p.$variant === 'cards' ? ' h-[696px]' : 'h-[738px]')}
`;

export const BannerProductContainer = tw.div<ProductBannerProps>`   
    relative 
    justify-between 
    lg:flex
  
    ${(p) => (p.$variant === 'cards' ? '  bg-grey-200' : '')}
`;

export const BannerProductTitleStyles = `
    pt-11 
    text-center 
    lg:pt-[60px] 
    lg:text-left 
    2xl:pt-0 
`;

export const BannerProductInformationContainer = tw.div<ProductBannerProps>`
    cl-start-1 
    col-end-12 
    flex 
    flex-col 
    gap-6 
    px-5 
    lg:col-start-1 
    lg:col-end-10 
    lg:h-auto  
    lg:w-[450px] 
    lg:place-content-center 
    lg:gap-8 lg:px-0 
    lg:pl-[60px] 
    xl:w-[686px]  
    2xl:w-[584px] 
    2xl:py-0 
    2xl:pl-[80px]
    ${(p) => (p.$variant === 'cards' ? 'h-[292px] bg-grey-200' : 'h-[384px]')}
`;

export const BannerProductImageContianer = tw.div<ProductBannerProps>`
    bg-lime-500 
    lg:h-[640px] 
    lg:w-[240px] 
    2xl:h-[824px] 
    2xl:w-[747px]
    ${(p) => (p.$variant === 'cards' ? 'h-[356px]' : 'h-[308px]')}
`;

export const CommonButtonStyles = `
    max-w-[130px]
    !px-5 
    !py-3 
    md:max-w-[165px]
    lg:!px-7
    lg:!py-[18px]
`;

export const BannerProductCardContainer = tw.div`
    absolute 
    right-[12%] 
    top-[29%] 
    z-2
    block 
    lg:right-[5%] 
    lg:top-auto 
    lg:self-center 
    xl:right-[7%] 
    2xl:right-[25%]
`;

export const BannerProductItemContainer = tw.div`
    absolute 
    right-[5%]
    top-[45%] 
    z-2 block 
    lg:right-[5%] 
    lg:top-auto 
    lg:self-center 
    xl:right-[7%] 
    2xl:right-[25%]
    inline-block
    py-[15px]
    lg:py-[80px]
    2xl:px-[76px]
    lg:px-[45px]
    bg-light
   
`;

export const Spacer = tw.div<ProductBannerProps>`
    hidden
    lg:block
    lg:h-[640px] 
    lg:min-w-[234px] 
    2xl:flex-1
    2xl:self-stretch
    2xl:h-[824px] 
    2xl:min-w-[234px] 
    2xl:w-[469px]
    2xl:max-w-[469px]
     ${(p) => (p.$variant === 'cards' ? ' bg-grey-200' : '')}
`;
