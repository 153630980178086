import tw from 'tailwind-styled-components';
import { VariantType } from 'helpers/types/common.type';

type BannerImageContainerProps = {
  $variant?: VariantType;
  $placement?: 'right' | 'left';
  $richTextCustom?: boolean;
};

export const BannerImageContainer = tw.div<BannerImageContainerProps>`
  bg-white
  flex
  flex-col
  w-full
  justify-between
  ${(props) => (props.$placement === 'right' ? 'md:flex-row' : 'md:flex-row-reverse')}

  ${(props) =>
    !props.$richTextCustom &&
    (props.$variant === VariantType.SPACED_SECONDARY || props.$variant === VariantType.SPACED_PRIMARY)
      ? 'xl:py-16 xl:px-[60px] md:p-10 py-6 px-5'
      : ''}
`;

export const BannerDetailContainer = tw.div<BannerImageContainerProps>` 
  bg-grey-100
  w-full
  h-auto
  flex
  item-center
  ${(props) =>
    props.$variant !== VariantType.FULL
      ? props.$placement === 'right'
        ? 'justify-start md:pl-[60px] xl:pl-[80px]'
        : 'justify-start md:pr-[60px] xl:pr-[80px]'
      : 'justify-end md:pr-[150px] xl:pr-[200px] md:pl-[60px]'}
`;

export const BannerImageDetailBox = tw.div<BannerImageContainerProps>`
  flex
  flex-col
  justify-center
  gap-y-4
  md:gap-y-5
  xl:gap-y-8
  w-full
  ${(props) => (props.$variant !== VariantType.FULL ? 'md:max-w-[722px]' : 'md:max-w-[476px]')}
  xl:max-w-[696px]
  px-5
  pt-8
  mb-[72px]
  md:px-0
  md:py-4
  md:mb-0
  ${(props) =>
    props.$variant !== VariantType.FULL
      ? props.$placement === 'right'
        ? 'md:mr-[230px] xl:mr-[150px]'
        : 'md:ml-[230px] xl:ml-[160px]'
      : ''}
`;

export const BannerImageSecondContainer = tw.div<BannerImageContainerProps>`
  w-full
  ${(props) => (props.$variant !== VariantType.FULL ? 'xl:max-w-[776px]' : 'xl:max-w-[492px]')}
  md:max-w-[240px]
  h-auto
  px-5
  md:px-0
  md:py-[67px]
  xl:py-[111px]
`;

export const BannerImageBox = tw.div<BannerImageContainerProps>`
  w-full
  md:w-[125%]
  ${(props) => (props.$variant !== VariantType.FULL ? 'xl:w-full max-w-[687px]' : 'xl:w-[117%] max-w-[727px]')}
  md:max-w-[300px]
  xl:max-w-[736px]
  relative
  bottom-[32px]
  md:bottom-0
  ${(props) =>
    props.$placement === 'right' ? 'md:right-[40%] xl:left-[-112px] xl:right-0' : 'md:left-[15%] xl:left-[112px]'}
`;
