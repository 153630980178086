import tw from 'tailwind-styled-components';
import CloseIcon from 'public/icons/figma/close-small.svg';

export const VideoCloseIcon: any = tw(CloseIcon)`
  flex
  items-end
  h-6
  w-6
  cursor-pointer
  text-light
`;

export const VideoContainer = tw.div`
  flex 
  h-screen 
  items-center 
  justify-center`;

export const VideoDiv = tw.div`
  h-[25.875rem] 
  w-[21.875rem] 
  md:h-[39.375rem] 
  md:w-[70rem] 
  xl:h-[42.25rem] 
  xl:w-[76.25rem]`;
