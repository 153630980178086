import Image, { ImageProps } from 'next/image';
import { IContentfulImage } from 'helpers/types';
import { isVideoUrl } from 'helpers/utils/is-video-file';

interface IProps {
  imageOrVideo: IContentfulImage;
  imageProps?: Omit<ImageProps, 'src'>;
  dataTestTd?: string;
}

const SimpleImageOrVideo = ({ imageOrVideo, imageProps, dataTestTd }: IProps) => {
  const url = imageOrVideo?.url;
  const alt = imageOrVideo?.title;
  const title = imageOrVideo?.title;
  const width = imageOrVideo?.width;
  const height = imageOrVideo?.height;
  const ariaLabel = imageOrVideo?.title;

  return (
    <>
      {imageOrVideo && isVideoUrl(url || '') ? (
        <video className="h-full w-full" controls autoPlay loop muted data-testid={dataTestTd}>
          <source src={url} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <Image
          src={url}
          width={width}
          height={height}
          title={title}
          alt={alt}
          loader={({ src }) => src}
          aria-label={ariaLabel}
          {...imageProps}
          priority
          data-testid={dataTestTd}
        />
      )}
    </>
  );
};

export default SimpleImageOrVideo;
