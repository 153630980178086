import tw from 'tailwind-styled-components';
import { CardVerticalContainers } from 'components/molecules/cards/product-card/product-card-vertical/styles';

export const CardContainers = tw(CardVerticalContainers)``;

export const CardVerticalBox = tw.div`
    p-4
    xl:p-8
`;

export const CardVerticalImageBody = tw.div`
    flex
    flex-row
    justify-between
`;

export const CardVerticalImageBox = tw.div`
    col-span-2
    flex
    flex-col
    justify-between
`;

export const CardVerticalImage = tw.div`
    relative
    h-[88px]
    w-[88px]
    overflow-hidden
    lg:h-[116px]
    lg:w-[116px]
`;

export const CardVerticalInfoBox = tw.div`
    border-b
    border-solid
    border-grey-200
    pb-4
    pt-3
    lg:pb-5
    lg:pt-4
`;

export const CardVerticalTags = tw.p`
    overflow-hidden
    text-ellipsis
    whitespace-normal
    rounded-md
    border
    border-solid
    border-grey-300
    bg-white
    px-2
    py-1.5
    text-center
    font-sans
    text-sm
    font-normal
    not-italic
    leading-4
    tracking-[0.14px]
    text-grey-400
    lg:px-3
    lg:py-2
    lg:text-base
    lg:leading-5
    lg:tracking-[0.16px]
`;

export const CardVerticalWeightText = tw.p`
    overflow-hidden
    text-ellipsis
    whitespace-normal
    pb-3
    font-arial
    text-sm
    font-bold
    not-italic
    leading-4
    tracking-[0.14px]
    text-grey-400
    lg:pb-4
    lg:text-base
    lg:leading-5
    lg:tracking-[0.16px]
`;

export const CardVerticalWeightBox = tw.div`
    grid
    grid-flow-row-dense
    grid-cols-2
    gap-2
    pb-6
    lg:pt-4
    pt-2.5
    md:pb-5
    xl:pb-8
`;

export const CardVerticalWeightSelection = tw.p`
    text-sm
    font-bold
    not-italic
    leading-4
    tracking-[0.14px]
    xl:text-base
    xl:leading-5
    xl:tracking-[0.16px]
`;
