import { MouseEventHandler, ReactElement } from 'react';

const QuantityButton = ({
  icon,
  onClick,
  disabled,
}: {
  icon: ReactElement;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
}) => {
  return (
    <button
      className="flex h-6 w-6 items-center justify-center"
      onClick={onClick}
      disabled={disabled}
      data-testid="quantity-button"
    >
      <span className={` text-grey-500 ${disabled ? '!text-grey-300' : ''}`}>{icon}</span>
    </button>
  );
};

export default QuantityButton;
