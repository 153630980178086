import tw from 'tailwind-styled-components';
import { TProps, Typography } from 'components/atoms/typography';

export const ImageContainer = tw.div<{ $style?: string }>`
  ${(props) => props?.$style}
`;

export const MultipleImages = tw.div`
  md:flex 
  md:flex-row
`;

export const CommontHtmlTag: React.FC<TProps> = tw(Typography)`
  !text-grey-400
  mb-2.5
`;

export const CommonIframeTag = tw.div<{ $video: string }>`
  ${(p) =>
    p.$video
      ? `flex 
  items-center 
  justify-center 
  mx-auto
  sm:w-full
  md:w-full`
      : 'mb-8 '}
`;

export const RichTextContainer = tw.div`
    [&_a]:font-bold
    [&_a]:underline
    [&_a]:text-leaf
    [&_ul]:list-disc 
    [&_ol]:list-decimal
    marker:[&_ul]:text-resin 
    marker:[&_ol]:text-leaf
    2xl:[&_ul]:pb-16
    2xl:[&_ol]:pb-16
    xl:[&_ul]:pb-10
    xl:[&_ol]:pb-10
    [&_ul]:pb-8
    [&_ol]:pb-8
`;

export const BottomPaddingClass = `pb-8 xl:pb-10 2xl:pb-16`;

export const RichTextPragraphs: React.FC<TProps> = tw(Typography)`
  !text-grey-400
  max-w-[736px]
  pb-8 
  xl:pb-10 
  2xl:pb-16
  [word-wrap:break-word]
`;

export const RichTextQuote = tw.blockquote`
 relative 
 max-w-[736px]
 before:text-resin 
 pl-5 
 lg:pl-9 
 xl:pl-0 
 before:left-[-2px] 
 lg:before:text-[5rem] 
 before:text-[3rem] 
 before:absolute 
 xl:before:left-[-43px] 
 lg:before:-top-6 
 before:-top-3 
 pb-8 
 xl:pb-10 
 2xl:pb-16
`;

export const RichTextList = tw.li`
  ml-5
  max-w-[736px]
  pb-4
  [&_p]:ml-3
  font-extrabold 
  text-[18px] 
  [&_p]:pb-0 
`;

export const BannerContainer = tw.div`
    mx-auto 
    flex 
    max-w-[1920px] 
    justify-end 
    lg:pr-[212px]
;`;

export const SourcesContainer = tw.div`
    -m-5 
    lg:-ml-[250px] 
    xl:-ml-[300px]
    2xl:-ml-[500px]
`;

export const ImageDescriptionStyle = `
    border-b-2 
    py-6 
    !text-grey-400
`;

export const BannerPadding = `-m-5 pb-20 lg:-ml-[250px] xl:-ml-[300px] 2xl:-ml-[500px] `;

export const VideoContainer = tw.div`
   lg:pr-[212px]
   pb-8 
   xl:pb-10 
   2xl:pb-16
`;

export const HeadingStyling = `
    pb-8 
    xl:pb-10 
    2xl:pb-16
`;

export const ImageGalleryContainer = tw.div`
   lg:pr-[212px]
   pb-8 
   xl:pb-10 
   2xl:pb-16
`;

export const ImageSectionContainer = tw.div<{ $Type: string }>`
    pb-8 
    xl:pb-10 
    2xl:pb-16
    ${(props) => (props.$Type == 'big' ? 'mx-[-20px] md:mx-[20px] lg:pr-[212px]' : 'lg:pr-[668px]')}
`;

export const FaqWrapper = tw.div`
    pb-8 
    xl:pb-10 
    2xl:pb-16
`;
