import tw from 'tailwind-styled-components';
type TButtonContainerProps = {
  $style?: string;
};

export const ButtonContainer = tw.div<TButtonContainerProps>`
    flex
    justify-between
    items-center
    rounded-[32px]
    border-2
    border-grey-300
    px-4
    py-3
    md:px-7
    md:py-[15px]
    xl:px-8
    xl:py-5
    gap-2
    ${(props) => props.$style}
`;
