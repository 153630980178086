import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { useDispatch } from 'react-redux';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import ResponsiveImageOrVideo from 'components/molecules/image-or-video/responsive-image-or-video';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, VariantType } from 'helpers/types';
import { ReplaceUrlWithHost, getPathMenuFiltersLink } from 'helpers/utils/common';
import { mediumDesktop, tablet } from 'helpers/utils/screensizes';
import { useAppSelector } from 'redux/hooks';
import { setOpenLoginDrawer } from 'redux/reducers/account';
import {
  BannerDetailContainer,
  BannerImageBox,
  BannerImageContainer,
  BannerImageDetailBox,
  BannerImageSecondContainer,
} from './styles';

type TImageProps = {
  desktopImage: IContentfulImage;
  mobileImage: IContentfulImage;
};

type TBannerImageProps = {
  title: string;
  description?: {
    json: any;
  };
  overlayImage: TImageProps;
  subTitle: string;
  richTextCustom?: boolean;
  variant?: VariantType;
  imagePlacement?: 'right' | 'left';
  image: TImageProps;
  handleSegmentEvent?: (text?: string) => void;
  ctaCollection: {
    title: string;
    url: string;
    longUrl: string;
    openInNewTab: boolean;
  };
  ctaRewards?: {
    title: string;
    url: string;
    longUrl: string;
    openInNewTab: boolean;
  };
};

const BannerImage = ({
  title,
  description,
  overlayImage,
  subTitle,
  richTextCustom,
  variant = VariantType.SPACED_SECONDARY,
  image,
  imagePlacement = 'right',
  ctaCollection,
  ctaRewards,
  handleSegmentEvent,
}: TBannerImageProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const [isMediumDesktop] = useMediaQuery(mediumDesktop);
  const dispatch = useDispatch();
  const host = useAppSelector((store) => store?.hostReducer?.host);
  const user = useAppSelector((state) => state.accountReducer.user);

  const placement = isTablet ? imagePlacement : 'right';
  const ctaUrl = ReplaceUrlWithHost(ctaCollection?.longUrl || '{{url}}/', host);
  const ctaRewardUrl = ReplaceUrlWithHost(ctaRewards?.longUrl, host);
  const newUrl = getPathMenuFiltersLink(ctaUrl);

  const imageHeightMediumDesktop = variant !== VariantType.FULL ? 484 : 520;
  const imageWidthMediumDesktop = variant !== VariantType.FULL ? 736 : 578;

  const imageHeightMobile = variant !== VariantType.FULL ? 484 : 448;
  const imageWidthMobile = variant !== VariantType.FULL ? 687 : 727;

  const imageHeightBelowDesktop = isTablet ? 240 : imageHeightMobile;
  const imageWidthBelowDesktop = isTablet ? 300 : imageWidthMobile;

  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  const openLogin = () => {
    dispatch(setOpenLoginDrawer(true));
    handleSegmentEvent(SegmentEvents.BANNER_CLICKED);
  };

  return (
    <BannerImageContainer ref={targetRef} $variant={variant} $placement={placement} $richTextCustom={richTextCustom}>
      <BannerDetailContainer $variant={variant} $placement={placement}>
        <BannerImageDetailBox $variant={variant} $placement={placement}>
          {subTitle && (
            <Typography variant="body-small-bold" className="!leading-6 !text-grey-500">
              {subTitle}
            </Typography>
          )}
          {title && <Typography variant="h3">{title}</Typography>}
          {description && (
            <ContentfulRichText
              data={description}
              renderNodeOptions={{
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <Typography variant="body-large" className="!text-grey-400">
                    {children}
                  </Typography>
                ),
              }}
            />
          )}
          {ctaCollection && (
            <CommonButton
              as={ctaCollection?.longUrl === '#rise-join-now' ? (user ? 'a' : 'button') : 'a'}
              color="green"
              button-type={variant === VariantType.SPACED_PRIMARY ? 'primary' : 'secondary'}
              href={ctaCollection?.longUrl === '#rise-join-now' ? (user ? ctaRewardUrl : '') : newUrl}
              host={host}
              target={
                ctaCollection?.longUrl === '#rise-join-now'
                  ? user && (ctaRewards?.openInNewTab || !ctaRewardUrl.includes(host))
                    ? '_blank'
                    : '_self'
                  : ctaCollection?.openInNewTab || !newUrl.includes(host)
                  ? '_blank'
                  : '_self'
              }
              onClick={!user && ctaCollection?.longUrl === '#rise-join-now' ? () => openLogin() : null}
              className="mt-2 w-fit md:mt-0"
            >
              {ctaCollection?.longUrl === '#rise-join-now'
                ? user
                  ? ctaRewards?.title
                  : ctaCollection?.title
                : ctaCollection?.title}
            </CommonButton>
          )}
        </BannerImageDetailBox>
      </BannerDetailContainer>
      <BannerImageSecondContainer
        style={{
          backgroundImage: `${image ? `url(${image?.desktopImage?.url}` : 'unset'}`,
        }}
        $variant={variant}
        $placement={placement}
      >
        <BannerImageBox $variant={variant} $placement={placement}>
          <ResponsiveImageOrVideo
            desktop={overlayImage?.desktopImage}
            mobile={overlayImage?.mobileImage}
            imageProps={{
              className: 'rounded-md',
              imageHeight: isMediumDesktop ? imageHeightMediumDesktop : imageHeightBelowDesktop,
              imageWidth: isMediumDesktop ? imageWidthMediumDesktop : imageWidthBelowDesktop,
            }}
          />
        </BannerImageBox>
      </BannerImageSecondContainer>
    </BannerImageContainer>
  );
};

export default BannerImage;
